import api from './api'

export default {
    upinsertPergunta(body){
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      }
      return api().post('/faq/pergunta/upinsert', body, config)
    },
    deletarPergunta(body){
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      }
      return api().post('/faq/pergunta/delete', body, config)
    },
    buscarPerguntas(body){
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      }
      return api().post('/faq/pergunta/select', body, config)
    },
    buscarCategorias(body){
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      }
      return api().post('/faq/categoria/select', body, config)
    },
    selecionarCurtidaFaqVideo(body){
      const config = {
        headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
      }
      return api().post('/faq/curtidas/select', body, config)
    }
}