<template>
    <v-container>
    <div class="header" style="">
      <span>
        <h3>Curtidas FAQs</h3>
      </span>
    </div>
    <div style="border-radius:5px;margin-bottom:5px;display:flex;flex-direction:column;">
         <v-container class="">
            <v-row>

              <v-col >
                <v-select v-model="sistema" label="Sistema" item-text="nome" item-value="id" :items="sistemasdb" outlined return-object hide-details dense >
                </v-select>
              </v-col>

              <v-col>
                <v-select color v-model="cliente" label="Cliente" item-text="nome" item-value="id" :items="clientes" outlined return-object hide-details dense>
                </v-select>
              </v-col>

              <v-col  >
                <v-text-field type="date" label="Dt Inicio" v-model="dataInicio" outlined return-object hide-details dense required></v-text-field>
              </v-col>

              <v-col>
                <v-text-field type="date" label="Dt Final" v-model="dataFinal" outlined return-object hide-details dense></v-text-field>
              </v-col>
              

            </v-row>

            <v-row style="margin-top:-10px;"> 
              <v-col>
              </v-col>
              <v-col>   
              </v-col>
              <v-col> 
                <v-row>
                    <v-col>
                      <vue-json-to-csv v-if="curtidasFaq && curtidasFaq.length > 0" :json-data="this.curtidasFaq" csv-title="Relatório de Curtidas FAQ">
                        <v-btn small elevation="" color="error" block>Exportar Relatório</v-btn>  
                      </vue-json-to-csv>     
                    </v-col>
                  </v-row>  
              </v-col>
              
              <v-col>
                  <v-row>
                    <v-col>
                        <v-btn small elevation="" @click="() => carregarRelatorioCurtidas()" color="info" block>Gerar</v-btn>       
                    </v-col>
                  </v-row>
              </v-col>
            </v-row>
          </v-container>    
    </div>

     <v-container class="">
        <v-row>
          <v-col>
            <v-data-table :items="curtidasFaq" :headers="headers" :items-per-page="6" >
               <template v-slot:[`item.id`]="{ item }">
                    <p>{{item}}</p>
                </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
  </v-container>
  </template> 

  <script>
  import faq from '@/services/faq.service.js'
  import SistemaServico from '@/services/sistema.service.js'
  import ClienteServico from '@/services/cliente.service.js'
  import dayjs from 'dayjs'
  import VueJsonToCsv from 'vue-json-to-csv'
  
  export default {
    components: { 
      VueJsonToCsv
    },
    data(){
      return{
        loading: false,
        clientes: [],
        clientesdb: [],
        cliente: {id:0,nome:'Todos'},
        headers: [
          { text: 'Cliente', value: 'nomecliente' },
          { text: 'Sistema', value: 'nomesistema' },
          { text: 'Pergunta', value: 'pergunta' },
          { text: 'Gostou', value: 'qtdecurtidas', align: 'center', width:'15%' },
          { text: 'Não Gostou', value: 'qtdenaocurtidas', align: 'center', width:'15%' },
        ],
        items: [],
        curtidasFaq: [],
        sistemas: [],
        sistemasdb: [],
        sistema: null,
        dataInicio: '',
        dataFinal: '',
      }
    },  
    methods:{
      teste(){
        
      },

      async requisitarClientes(){
        let lclientes = await ClienteServico.buscar().then(res=> res.data)
        this.clientes = [{id:0,nome:'Todos'}]
        this.clientes = this.clientes.concat(lclientes)
        
        // const clientes = await ClienteServico.buscar().then(res=> res.data)
        // this.clientes = clientes.map(res => res.nome)
        // this.clientesdb = clientes
      },

      async requisitarSistemas(){
      const sistemas = await SistemaServico.buscar().then(res=> res.data)
      this.sistemas = sistemas.map(res => res.nome)
      this.sistemasdb = sistemas
    },

      async carregarRelatorioCurtidas(){
        //alert(JSON.stringify(this.cliente,null,2))

        let parametros = {idsistema: this.sistema.id, idcliente: this.cliente.id, dtinicio: this.dataInicio, dtfinal: this.dataFinal}

        let dados = {idsistema: this.sistema.id, dtinicio: this.dataInicio, dtfinal: this.dataFinal}

        if (parametros.idcliente == 0){
          this.curtidasFaq = await faq.selecionarCurtidaFaqVideo(dados).then(res=> res.data)
        }else{
          this.curtidasFaq = await faq.selecionarCurtidaFaqVideo(parametros).then(res=> res.data)
        }

     // alert( JSON.stringify(this.curtidasFaq,null,2) )
        
      },

    },
    async mounted(){
      await this.requisitarClientes()
      await this.requisitarSistemas()
      this.dataInicio = dayjs().subtract(7,'days').format('YYYY-MM-DD')
      this.dataFinal = dayjs().format('YYYY-MM-DD')
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .header{
    display: flex; 
  
    >h3{
      letter-spacing: 0.02em;
    }
    margin: 1em auto;
    
    height: fit-content;
    border-radius: 10px;
    >span{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  .section-header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1em 0;
  }
  
  .section-nav{
    display: flex;
    align-items: center;
    justify-content: center;
  
    ul{
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: #eff3e7;
      padding: 1em;
      width: 100%;
  
      li{
        margin: 0 .5em;
        padding: 1em 1.5em;
        border-radius: 50px;
        background: #eff3e7;
    box-shadow:  4px 4px 10px #d9d9d9,
               4px 4px 10px #ffffff;
      }
    }
  }
  
  .wrapper-list{
    margin: 0 auto;
    width: 60vw;
    max-width: 100%;
    @media screen and (max-width: 768px) {
      width: 90vw;    
    }
  }
  
  .btn{
        padding: .75em 1em;
        line-height: 1.5;
        color: white;
        font-weight: bold;
        letter-spacing: 0.02em;
        background: #1377f2;
        border-radius: 10px;
        text-transform: capitalize;
    }
  
  .grow{
    flex: 1;
  }
  
  </style>