<template>
    <ListarRelatorio />
</template>

<script>
import ListarRelatorio from '../components/RelatorioFaq/ListarRelatorio.vue';

export default {
  components: { ListarRelatorio },
  data(){
    return{}
  }
}
</script>

<style>

</style>